var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "nh-body"
  }, [_c("div", {
    staticClass: "trust-nh",
    class: {
      "trust-nh-mobile": _vm.device == "mobile"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "title margin-bottom-60"
  }, [_c("span", [_vm._v(_vm._s(_vm.trustTitle[parseInt(_vm.language) - 1]))])]), _vm._l(_vm.mapText, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "article-title margin-bottom-5"
    }, [_c("a", {
      attrs: {
        href: item.href
      }
    }, [_c("span", {
      staticClass: "color-base font-bold font-size-12em"
    }, [_vm._v(_vm._s(item.subTitle[parseInt(_vm.language) - 1]))])])]), _c("div", {
      staticClass: "article-content margin-bottom-20"
    }, [_c("span", [_vm._v(_vm._s(item.content[parseInt(_vm.language) - 1]))])])]);
  })], 2), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "circle-big"
  })]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "circle-small"
  })]);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };